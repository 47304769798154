(function($) {

    // display popup when browsing these CAT IDs
    var categoryIDs = ['2669', '1582', '2065', '1584', '14225', '1600', '1602', '1618', '1619', '1684', '1685'];

    var regEx = /product[s]?\/(\d+)/g;
    var currentCATMatches = regEx.exec(location.href);
    var currentCAT = currentCATMatches ? currentCATMatches[1] : null;

    // minimum miliseconds to browse the CAT IDs before displaying the popup
    var browseTime = 7000;

    var signupEnd = new Date(2016, 8, 28, 24, 0, 0);
    var today = new Date();

    if (today > signupEnd) {
        return;
    }

    if ($.cookie("waitlist_christmas")) {
        return;
    }

    if (!currentCAT || categoryIDs.indexOf(currentCAT) < 0) {
        return;
    }

    var initPopup = function() {
        generic.template.get({
            path: '/templates/waitlist_christmas_popup.tmpl',
            callback: function(html) {
                generic.overlay.launch({
                    content: html,
                    cssStyle: {
                        backgroundImage: 'url("/media/export/cms/pop_up_christmas/christmas_popup_background.jpg")',
                        backgroundRepeat: 'no-repeat',
                        border: '14px solid white',
                        padding: '0px',
                        width: '600px',
                        height: '208px'
                    },
                });

                //tablet workaround, so the popup doesn't stretch on the whole screen
                if (generic.isMobile() && $(window).width() > 600) {
                    $('#foreground-node').attr('style', 'width: 600px !important; height: 208px !important; background-image: url("/media/export/cms/pop_up_christmas/christmas_popup_background.jpg"); backround-repeat: no-repeat; border: 14px solid white; padding: 0px;');
                }
                if (generic.isMobile()) {
                     $('.thankyou').attr('style', 'position: absolute');
                }
                $(document).trigger('waitlist_christmas_displayed');
                $.cookie("waitlist_christmas", 1);
            }
        });

        $(document).on('waitlist_christmas_displayed', function() {
            var thisFormatter = $('#waitlist_christmas');

            // Submit form
            var form = thisFormatter.find('.signup-forms .waitlist-signup #waitlist-signup'); 
            form.find('.form-submit.button').on('click', function(e) {
                e.preventDefault();

                // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces
                var fields = [
                    'PC_EMAIL_ADDRESS',
                    'REQUEST_TYPE',
                    'EVENT_ID',
                    'LAST_SOURCE',
                ];

                var paramObj = {};
                paramObj['_SUBMIT'] = 'waitlist';

                // loop through all the fields and get the values
                $.each(fields, function(index, value){
                    paramObj[value] = form.find("input[name=" + value + "]").val()
                });

                // Send the data via a json rpc call
                generic.jsonrpc.fetch({
                    method : 'rpc.form',
                    params: [paramObj],
                    onSuccess:function(jsonRpcResponse) {
                        var response = jsonRpcResponse.getData();

                        // show normal success message since user was already signed up
                        $('body').addClass('popup-offer');
                        if ($('body.popup-offer').hasClass('pg')) {
                            $('body.pg').addClass('popup-signedup');
                        }

                        thisFormatter.find('.form-submit-wrap').hide();
                        thisFormatter.find('#signup').css('visibility', 'hidden');;
                        thisFormatter.find('.thankyou').show();
                    },
                    onFailure: function(jsonRpcResponse) {
                        var messages = jsonRpcResponse.getMessages();
                        $('.error').empty().html(messages[0].text).removeClass('hidden');
                    }
                });
            });
        });
    }
    
    // kick off, display popup after a certain amount of browsing time
    var delayTimer = setTimeout(initPopup, browseTime);
})(jQuery);
